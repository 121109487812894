import { getApolloClient } from "../../apollo/client";
import gql from "graphql-tag";
import { createServiceRequestMutation } from "../mutation/createServiceRequest.mutation";
import LocalStorageConst from "../../constant/localstorage.constant";
import { setKeyValueInLocalStorage } from "../../services/localstorage/setKeyValueInLocalStorage";

const createServiceRequest = async (formData) => {
  const res = await getApolloClient().mutate({
    mutation: gql`
      ${createServiceRequestMutation(formData)}
    `,
  });

  const vendorLeads = res.data.createServiceRequest.result.vendorLeads
  setKeyValueInLocalStorage(LocalStorageConst.vendorLeadByServiceId + res.data.createServiceRequest.result.serviceRequestId, JSON.stringify(vendorLeads));

  return res.data.createServiceRequest.result.serviceRequestId;
};

export { createServiceRequest };
