import { getApolloClient } from "../../apollo/client";
import gql from "graphql-tag";
import { getZipCodeQuery } from "../query/getZip.query";

const getZipCodeRequest = async (lat, long) => {
    try {
      const res = await getApolloClient().mutate({
        mutation: gql`
          ${getZipCodeQuery(lat, long)}
        `,
      });
      return res.data.getZipCode;
    } catch (ex) {
      console.log(ex);
    }
  };

  export { getZipCodeRequest };