import SecureLS from "secure-ls";
import LocalStorageConst from "../../constant/localstorage.constant";

const ls = new SecureLS({
  encodingType: "des",
  isCompression: false,
  encryptionSecret: "OJRESKG0LK",
});

const getUserUc = () => {
  // console.log('getUserUc');
  const user = ls.get(LocalStorageConst.uc);
  // console.log(user.userRole);
  return user;
};
const setUserUc = (user) => {
  // console.log('setUserUc');
  // console.log(user.userRole);
  ls.set(LocalStorageConst.uc, user);
};

export { setUserUc, getUserUc};
