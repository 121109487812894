const createServiceRequestMutation = (formData) => {
  let latInput;
  let longInput;

  if (formData.selectedTrim == '') {
    formData.selectedTrim = 'null';
  }
  if (formData.requestLoc.x != null && formData.requestLoc.y != null) {
    latInput = '"' + formData.requestLoc.x + '"';
    longInput = '"' + formData.requestLoc.y + '"';
  } else {
    latInput = null;
    longInput = null;
  }

  const query = `
  mutation MyMutation {
    createServiceRequest(
      input: {
        obdiiCode: "${formData.OBDIIReaderCode}", 
        radius: ${formData.selectedRadius}, 
        requestDescription: "${formData.additionalComments}", 
        requestLat: ${latInput},
        requestLong: ${longInput}, 
        serviceId: ${formData.selectedProblemType}, 
        urgencyId: ${formData.selectedUrgencyType}, 
        vehicleMake: "${formData.selectedMake}", 
        vehicleModel: "${formData.selectedModel}", 
        vehicleTrim: "${formData.selectedTrim}", 
        vehicleYear: "${formData.selectedYear}", 
        vin: "${formData.vinNo}", 
        zipCode:"${formData.zipCode}"
      }
    )
    {
      clientMutationId
      result {
        serviceRequestId
        vendorLeads
      }
    }
  }`;

  return query;
};

export { createServiceRequestMutation };
