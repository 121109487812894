import gql from 'graphql-tag';
import { getApolloClient } from '../../apollo/client';
import { getServiceCategoryQuery } from '../query/getServiceCategory.query';

const getServiceCategoryRequest = async () => {
  try {
    const res = await getApolloClient().mutate({
      mutation: gql`
        ${getServiceCategoryQuery()}
      `,
    });
    return res.data.allServiceCategories.nodes;
  } catch (ex) {
    console.log(ex);
  }
};

export { getServiceCategoryRequest };
