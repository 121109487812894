import { getApolloClient } from "../../apollo/client";
import gql from "graphql-tag";
import { getVehicleTrimsQuery } from "../query/getVehicleTrims.query";

const getVehicleTrimsRequest = async (year, make, model) => {
  try {
    const res = await getApolloClient().mutate({
      mutation: gql`
        ${getVehicleTrimsQuery(year,make,model)}
      `,
    });
    return res.data.getVehicleTrims.edges;
  } catch (ex) {
    console.log(ex);
  }
};

export { getVehicleTrimsRequest };
