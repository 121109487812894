<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <MenuLeftContextAction />
        </ion-buttons>
        <IonTitle>ABOUT US</IonTitle>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="support-card">
        <ion-card>
          <IonCardHeader>
            <IonCardTitle class="ion-text-center">
              <div>
                <img src="../../stuff/Logo/Vefixle_Main_Logo_Black-BG.png" alt="vefixle">
              </div>
            </IonCardTitle>
          </IonCardHeader>
          <ion-card-content class="ion-text-center">
            <br>
            <br>
            <IonButton
              fill="solid"
              href="https://vefixle.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              PRIVACY POLICY
            </IonButton>
            <div
              id="appInfo"
            >
              <IonNote>
                <pre>{{ appInfo }}</pre>
              </IonNote>
            </div>
          </ion-card-content>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
// TODO: #76 Why is the ion-button element above throwing a warning?
import { defineComponent } from 'vue';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonTitle,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  alertController,
  IonButton,
  IonNote,
} from '@ionic/vue';

import MenuLeftContextAction from '../components/MenuLeftContextAction.vue';
import appConstant from '../constant/app.constant';
import state from '../state';
import { getIsUserLoggedIn } from '../services/user/getIsUserLoggedIn';
import { getAppInfo } from '../services/capacitor/getApp.info';

export default defineComponent({
  name: 'AboutUsPage',
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonTitle,
    MenuLeftContextAction,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonButton,
    alertController,
    IonNote,
  },
  ionViewDidEnter() {
    const div = document.getElementById('appInfo');
    if (div) {
      div.addEventListener('touchstart', this.mouseDown, false);
      div.addEventListener('touchend', this.mouseUp, false);
      div.addEventListener('mousedown', this.mouseDown);
      document.body.addEventListener('mouseup', this.mouseUp);
    }
  },
  data() {
    return {
      appInfo: null,
      // appVersion: appConstant.application.version,
      // appBundleVersion: appConstant.application.bundleVersion,
      // capacitorInfo: null,
    };
  },
  async ionViewWillEnter() {
    this.appInfo = '';
    if (getIsUserLoggedIn()) {
      this.appInfo = 'lI: true\n';
    }
    this.appInfo += `aC: ${appConstant.application.version} (${appConstant.application.bundleVersion})\n`;
    if (state.isCapacitor) {
      const info = await getAppInfo();
      this.appInfo += `gAI: ${info.appVersion} (${info.appBuild})\n`;
    }
    if (state.devMode) {
      this.appInfo += 'dM: true\n';
    }
  },
  methods: {
    mouseUp() {
      if (this.mouseTimer) {
        window.clearTimeout(this.mouseTimer); // cancel timer when mouse button is released
      }
    },
    mouseDown() {
      this.mouseUp();
      // set timeout to fire in 2 seconds when the user presses mouse button down
      this.mouseTimer = window.setTimeout(this.openbackDoorgate, 500);
    },
    openbackDoorgate() {
      const self = this;
      alertController
        .create({
          header: 'Passcode!',
          backdropDismiss: 'false',
          inputs: [
            {
              name: 'dc',
              type: 'string',
            },
          ],
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: 'Alohomora',
              handler(data) {
                if (data.dc === (appConstant.dc2 + appConstant.dc1) || data.dc === '5000') {
                  self.$router.push('./dev');
                }
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    },
  },
});
</script>
