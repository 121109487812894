const RADIUS_VALUES = [
  {
    id: 5,
    value: '5 miles',
  },
  {
    id: 10,
    value: '10 miles',
  },
];
export { RADIUS_VALUES };
