<template>
  <ion-card v-if="adData != null">
    <ion-card-content class="ion-text-center">
      <a
        :href="adData.target_url"
        target="_blank"
        rel="noopener noreferrer"
      ><img :src="adData.image_url" alt="{{ adData.ad_description }}"></a>
      <br>
      <ion-label>{{ adData.ad_description }}</ion-label>
      <br>
      <ion-button @click="goAdPage">
        LEARN MORE
      </ion-button>
      <br>
    </ion-card-content>
    <ion-note class="ion-float-right ion-padding-horizontal">
      Ad
    </ion-note>
  </ion-card>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonLabel,
  IonText,
  IonButton,
  IonNote,
} from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AdvertisementItem',
  components: {
    IonCard,
    IonCardContent,
    IonText,
    IonLabel,
    IonNote,
    IonButton,
  },
  props: {
    adData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  methods: {
    goAdPage() {
      window.open(this.adData.target_url);
    },
  },
});
</script>
