import { getUserUc } from './setUserUc';

const roles = {
  customer: "customer",
  vendor: "vendor",
};

const getDashboard = () => {
  const user = getUserUc();
  if (user.userRole === roles.customer) {
    return "/customer-dashboard";
  }
  if (user.userRole === roles.vendor) {
    return "/service-provider-dashboard";
  }
};

export { getDashboard };
