import LocalStorageConst from '../../constant/localstorage.constant';
import {getKeyValueFromLocalStorage} from '../localstorage/getKeyValueFromLocalStorage'
  import { getUserUc } from './setUserUc';


const getIsUserLoggedIn = () => {
  const user = getUserUc()
  const authToken = getKeyValueFromLocalStorage(
    LocalStorageConst.authToken,
  );
  if (user.userRole && authToken) {
    return true;
  }
  return false;
}

export { getIsUserLoggedIn }