const getVehicleYearsQuery = () => {
    const query = `
    query MyQuery {
        getVehicleYears {
          edges {
            node
          }
        }
      }
      `;
    return query;
  };
  
  export { getVehicleYearsQuery };
  
