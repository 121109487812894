import LocalStorageConst from '../../constant/localstorage.constant';
import {
  getKeyValueFromLocalStorage,
} from '../localstorage/getKeyValueFromLocalStorage';

const checkGeoPermission = () => {
  const permissionCode = getKeyValueFromLocalStorage(
    LocalStorageConst.geoLocationPermissionGiven,
  );
  /**
     * Code
     * 1 = PERMISSION_DENIED
     * 2 = POSITION_UNAVAILABLE
     * 3 = Permission Allowed - CUSTOM value
     * null = If nothing already there then try soft permission ask first
  */
  switch (Number(permissionCode)) {
    case 1:
      return false;
    case 2:
      return false;
    case 3:
      return true;
    default:
      return null;
  }
};

export { checkGeoPermission };
