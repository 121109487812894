<template>
  <div>
    <ion-menu-button />
  </div>
</template>
<script>
import { IonMenuButton, IonButton, IonIcon } from '@ionic/vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import { chevronBackOutline } from 'ionicons/icons';
import { getIsUserLoggedIn } from '../services/user/getIsUserLoggedIn';

export default {
  components: { IonMenuButton, IonButton, IonIcon },
  props: {
    showBackButton: {
      type: Boolean,
      default: true,
    },
    backPath: {
      type: String,
      default: '/first-time-user',
    },
  },
  data() {
    return {
      chevronBackOutline,
      userLoginStatus: getIsUserLoggedIn(),
    };
  },
  mounted() {
    this.userLoginStatus = getIsUserLoggedIn();
  },
};
</script>
