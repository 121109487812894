const getServicesQuery = () => {
    const query = `
    query getAllServices {
      allServices {
        nodes {
          serviceId
          serviceLabel
          serviceCategoryId
        }
      }
    }`;
    return query;
  };
  
  export { getServicesQuery };
  