import { getApolloClient } from "../../apollo/client";
import gql from "graphql-tag";
import { getAllUrgenciesQuery } from "../query/getUrgencies.query";

const getAllUrgenciesRequest = async () => {
  try {
    const res = await getApolloClient().mutate({
      mutation: gql`
        ${getAllUrgenciesQuery()}
      `,
    });
    return res.data.allUrgencies.edges;
  } catch (ex) {
    console.log(ex);
  }
};

export { getAllUrgenciesRequest };
