<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/" mode="ios" />
        </ion-buttons>
        <ion-title class="ion-text-center">
          SERVICE PROVIDER
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :key="reRenderKey">
      <div class="center-form">
        <ion-list class="form-list">
          <ion-item-divider>
            <ion-label> First, tell us a little about your business.</ion-label>
          </ion-item-divider>
          <br>
          <ion-item :class="{ error: errorsRequiredFields.vendorName }">
            <ion-label position="stacked">
              Company Name <b>*</b>
            </ion-label>
            <ion-input
              v-model="formData.vendorName"
              type="text"
              placeholder="Company Name"
            />
          </ion-item>
          <ion-item :class="{ error: errorsRequiredFields.vendorAddress }">
            <ion-label position="stacked">
              Address <b>*</b>
            </ion-label>
            <ion-input
              v-model="formData.vendorAddress"
              type="text"
              placeholder="Address"
            />
          </ion-item>
          <ion-item :class="{ error: errorsRequiredFields.vendorCity }">
            <ion-label position="stacked">
              City <b>*</b>
            </ion-label>
            <ion-input
              v-model="formData.vendorCity"
              type="text"
              placeholder="City"
            />
          </ion-item>
          <ion-item :class="{ error: errorsRequiredFields.vendorState }">
            <ion-label position="stacked">
              State <b>*</b>
            </ion-label>
            <ion-select
              v-model="formData.vendorState"
              interface="action-sheet"
              placeholder="Select"
            >
              <ion-select-option
                v-for="state in stateList"
                :key="state.abbreviation"
                :value="state.abbreviation"
              >
                {{ state.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item :class="{ error: errorsRequiredFields.vendorZip }">
            <ion-label position="stacked">
              ZIP <b>*</b>
            </ion-label>
            <ion-input
              v-model="formData.vendorZip"
              type="number"
              placeholder="ZIP"
            />
          </ion-item>
          <ion-item :class="{ error: errorsRequiredFields.vendorPhone }" lines="none">
            <ion-label position="stacked">
              Phone <b>*</b>
            </ion-label>
            <ion-input
              v-model="formData.vendorPhone"
              type="tel"
              placeholder="Phone"
            />
          </ion-item>
          <ion-item-divider>
            <ion-label>
              What service(s) do you provide?
            </ion-label>
          </ion-item-divider>
          <h6 class="checkbox-header">
            Check all that apply:
          </h6>
          <ion-list>
            <ion-item
              v-for="(vehfixleCategory, index) in vehfixleCategoriesRows"
              :key="index"
              lines="none"
              class="checkbox-item"
            >
              <br>
              <ion-checkbox
                slot="start"
                mode="md"
                :value="vehfixleCategory.serviceCategory"
                @click="onServiceCategoryCheckChange(vehfixleCategory.serviceCategoryId)"
              />
              <ion-label>{{ vehfixleCategory.serviceCategory }}</ion-label>
            </ion-item>
          </ion-list>
          <ion-item :class="{ error: errorsRequiredFields.selectedRadius }">
            <ion-label position="stacked">
              What service area do you cover? <b>*</b>
            </ion-label>
            <ion-select
              v-model="formData.selectedRadius"
              interface="action-sheet"
              placeholder="Select"
            >
              <ion-select-option
                v-for="possibleRadiusValue in possibleRadiusValues"
                :key="possibleRadiusValue.id"
                :value="possibleRadiusValue.id"
              >
                Up to {{ possibleRadiusValue.value }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item :class="{ error: errorsRequiredFields.email }">
            <ion-label position="stacked">
              What is your email address? <b>*</b>
            </ion-label>
            <ion-input
              v-model="formData.email"
              placeholder="Enter your email address"
            />
          </ion-item>
          <ion-item :class="{ error: errorsRequiredFields.password }">
            <ion-label position="stacked">
              What is your password? <b>*</b>
            </ion-label>
            <ion-input
              v-model="formData.password"
              type="password"
              placeholder="Enter password"
            />
          </ion-item>
        </ion-list>
      </div>
      <br>
      <div class="ion-text-center">
        <ion-button
          v-if="!processingSubmit"
          @click="createVendor()"
        >
          CONTINUE
        </ion-button>
        <ion-spinner
          v-if="processingSubmit"
          name="circles"
        />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
  IonList,
  IonLabel,
  IonItem,
  IonItemDivider,
  IonInput,
  IonCheckbox,
  IonSelect,
  alertController,
  IonSelectOption,
  IonBackButton,
  IonSpinner,
  IonTitle,
} from '@ionic/vue';
import Ajv from 'ajv';
// eslint-disable-next-line import/no-extraneous-dependencies
import { locateOutline } from 'ionicons/icons';
import MenuLeftContextAction from '../components/MenuLeftContextAction.vue';
import { getServiceCategoryRequest } from '../graphql/services/getServiceCategory';
import { RADIUS_VALUES } from '../constant/service-request/radius.constant';
import { createVendorRequest } from '../graphql/services/createVendorRequest';
import { serviceProviderSchema } from '../graphql/schema/serviceProviderSchema';
import { getIsUserLoggedIn } from '../services/user/getIsUserLoggedIn';
import { loginRequest } from '../graphql/services/loginRequest';
import { STATE_LIST } from '../constant/geography/state.constant';

let vendorServiceCategories = [];
export default {
  name: 'ServiceProviderOnboardingPage',
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonList,
    IonLabel,
    IonItem,
    IonItemDivider,
    IonInput,
    IonCheckbox,
    IonSelect,
    IonSelectOption,
    MenuLeftContextAction,
    IonBackButton,
    IonSpinner,
    IonTitle,
  },
  data() {
    return {
      userLoggedId: getIsUserLoggedIn(),
      formData: {
        email: null,
        password: null,
        vendorName: null,
        vendorAddress: null,
        vendorCity: null,
        vendorState: null,
        vendorPhone: null,
        vendorZip: null,
        selectedRadius: null,
      },
      processingSubmit: false,
      locateOutline,
      possibleRadiusValues: RADIUS_VALUES,
      vehfixleCategoriesRows: [],
      errorsRequiredFields: {},
      stateList: STATE_LIST,
      reRenderKey: 0,
    };
  },
  created() {
    this.userLoggedId = getIsUserLoggedIn();
    this.getServiceCategory();
  },
  methods: {
    onLogin() {
      this.$router.push({
        path: '/login',
      });
    },

    async getServiceCategory() {
      this.vehfixleCategoriesRows = await getServiceCategoryRequest();
    },
    validateCreateVendorRequest() {
      this.errorsRequiredFields = {};
      const mutationFields = Object.keys(this.formData).reduce((acc, item) => {
        if (this.formData[item] !== null) {
          acc[item] = this.formData[item];
        }
        return acc;
      }, {});
      const ajv = new Ajv({ allErrors: true });
      const validate = ajv.compile(serviceProviderSchema);
      const valid = validate(mutationFields);
      if (validate.errors) {
        this.errorsRequiredFields = validate.errors.reduce((acc, item) => {
          if (item.params.missingProperty) {
            acc[item.params.missingProperty] = true;
          }
          return acc;
        }, {});
      }
      return valid;
    },
    async onServiceCategoryCheckChange(item) {
      if (vendorServiceCategories.includes(item)) {
        vendorServiceCategories = vendorServiceCategories.filter((value) => value !== item);
      } else {
        vendorServiceCategories.push(item);
      }
    },
    async createVendor() {
      if (this.validateCreateVendorRequest()) {
        this.processingSubmit = true;
        try {
          await createVendorRequest(this.formData, vendorServiceCategories);
          await loginRequest(this.formData.email, this.formData.password);

          const serviceProviderRegisterAlert = await alertController.create({
            header: 'Thank you for your interest!',
            message: 'A VEFIXLE representative will follow up shortly to activate your account.',
            buttons: ['OK'],
          });
          await serviceProviderRegisterAlert.present();
          await this.$router.push({
            path: '/service-provider-dashboard',
          });
        } catch (err) {
          this.processingSubmit = false;
          //  Looks like you have already registered, Please sign in
          const InvalidRequestalert = await alertController.create({
            header: 'Returning User',
            message: "We've seen a request from you before. Either log in, or please contact help@vefixle.com for more assistance.",
            buttons: ['OK'],
          });

          await InvalidRequestalert.present();
        }
        this.processingSubmit = false;
      } else {
        const InvalidRequestalert = await alertController.create({
          header: 'Error!',
          message: "Please fill out all items in the form above before submitting. Required fields are marked with '*'",
          buttons: ['OK'],
        });
        await InvalidRequestalert.present();
      }
    },
  },
  ionViewWillEnter() {
    this.userLoggedId = getIsUserLoggedIn();
    this.reRenderKey += 1;
    this.formData = {
      email: null,
      password: null,
      vendorName: null,
      vendorAddress: null,
      vendorCity: null,
      vendorState: null,
      vendorPhone: null,
      vendorZip: null,
      selectedRadius: null,
    };
  },
};
</script>
