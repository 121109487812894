
import { getApolloClient } from "../../apollo/client";
import gql from "graphql-tag";
import { createVendorRequestMutation } from "../mutation/createVendor.mutation";
import LocalStorageConst from "../../constant/localstorage.constant";
import { setKeyValueInLocalStorage } from "../../services/localstorage/setKeyValueInLocalStorage";

const createVendorRequest = async (formData, vendorServiceCategories) => {
  try {
    const res = await getApolloClient().mutate({
      mutation: gql`
        ${createVendorRequestMutation(formData, vendorServiceCategories)}
      `,
    });
    if (res.data.onboardVendor.jwtToken) {
      setKeyValueInLocalStorage(LocalStorageConst.authToken, res.data.onboardVendor.jwtToken);
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export { createVendorRequest };
