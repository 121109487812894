<template>
  <div class="dashboard-item-container">
    <ion-card class="ion-padding dashboard-card">
      <IonItem lines="none">
        <IonText class="request-header">
          <b>Request ID: {{ vehfixleService.service_request_uuid }}</b> <br>
          Date: {{ requestDate }}
        </IonText>
        <ion-icon
          v-if="expanded"
          slot="end"
          :ios="chevronUpCircleOutline"
          :md="chevronUpCircleOutline"
          size="large"
          color="white"
          @click="changeExpansion()"
        />
        <ion-icon
          v-if="!expanded"
          slot="end"
          :ios="chevronDownCircleOutline"
          :md="chevronDownCircleOutline"
          size="large"
          color="white"
          @click="changeExpansion()"
        />
      </IonItem>
      <ion-card-content v-if="expanded">
        <div class="ion-text-left">
          <IonText color="dark">
            <h2><b>Service Request Info:</b></h2>
          </IonText>
          <hr class="hr-rule">
          <RequestorInfo
            :urgency-types="urgencyTypes"
            :vehfixle-service="vehfixleService"
          />
          <IonText color="dark">
            <h2><b>Vehicle Info:</b></h2>
          </IonText>
          <hr class="hr-rule">
          <VehicleInfo
            v-if="vehfixleService"
            :vehicle="vehfixleService"
          />
        </div>
        <div>
          <IonText color="dark">
            <h2><b>Vendor Leads Info:</b></h2>
          </IonText>
          <div v-if="!vehfixleService">
            *No vendor leads found
          </div>
        </div>
      </ion-card-content>
    </ion-card>
  </div>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonText,
  IonNote,
  IonCardHeader,
  IonTitle,
  IonIcon,
  IonItem,
} from '@ionic/vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  chevronUpCircleOutline,
  chevronDownCircleOutline,
} from 'ionicons/icons';
import { defineComponent } from 'vue';
import VehicleInfo from './VehicleInfo.vue';
import VendorLeadsInfo from './VendorLeadsInfo.vue';
import RequestorInfo from './RequestorInfo.vue';
import { getFormattedDateTime } from '../services/utilities/formatDateTime';

export default defineComponent({
  name: 'ServiceProviderDashboardItem',
  components: {
    IonCard,
    IonCardContent,
    IonNote,
    IonText,
    VehicleInfo,
    VendorLeadsInfo,
    RequestorInfo,
    IonCardHeader,
    IonTitle,
    IonIcon,
    IonItem,
  },
  props: {
    vehfixleService: {
      type: Object,
      required: true,
    },
    urgencyTypes: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return {
      chevronUpCircleOutline,
      chevronDownCircleOutline,
    };
  },
  data() {
    return {
      requestDate: '',
      expanded: true,
    };
  },
  async created() {
    this.requestDate = new Date(this.vehfixleService.request_date).toDateString();
  },
  methods: {
    changeExpansion() {
      this.expanded = !this.expanded;
    },
  },
});
</script>
