<template>
  <div class="ion-page">
    <ion-header>
      <ion-toolbar>
        <ion-title>User already registered</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="ion-text-center">
        <ion-card>
          <ion-card-content>
            We've seen a request from you before, but you did not create a
            password. Please contact customer service for assistance.
          </ion-card-content>
        </ion-card>
      </div>
    </ion-content>
  </div>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardContent,
} from '@ionic/vue';

export default {
  name: 'UpdatePasswordScreen',
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardContent,
  },
};
</script>
