import { getApolloClient } from "../../apollo/client";
import gql from "graphql-tag";
import { getAdsMutation } from "../mutation/getAds.mutation";

const getAdRequest = async () => {
  try {
    const res = await getApolloClient().mutate({
      mutation: gql`
        ${getAdsMutation()}
      `,
    });
    return res.data.getAds.adJson[0];
  } catch (ex) {
    console.log(ex);
  }
};

export { getAdRequest };
