import { getApolloClient } from "../../apollo/client";
import gql from "graphql-tag";
import { getVehicleMakesQuery } from "../query/getVehicleMakes.query";

const getVehicleMakesRequest = async (year) => {
  try {
    const res = await getApolloClient().mutate({
      mutation: gql`
        ${getVehicleMakesQuery(year)}
      `,
    });
    return res.data.getVehicleMakes.edges;
  } catch (ex) {
    console.log(ex);
  }
};

export { getVehicleMakesRequest };
