<template>
  <ion-page :key="reRenderKey">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <MenuLeftContextAction />
        </ion-buttons>
        <IonTitle>DASHBOARD</IonTitle>
      </ion-toolbar>
    </ion-header>
    <ion-content v-if="vehfixleServicesRows != null">
      <ion-list v-show="vehfixleServicesRows?.length">
        <ServiceProviderDashboardItem
          v-for="(vehfixleService, index) in vehfixleServicesRows"
          :key="index"
          :vehfixle-service="vehfixleService"
          :urgency-types="urgencyTypes"
        />
      </ion-list>
    </ion-content>
    <IonTitle
      v-else
      class="ion-text-center"
    >
      No Leads Found
    </IonTitle>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonTitle,
  IonList,
} from '@ionic/vue';
import MenuLeftContextAction from '../components/MenuLeftContextAction.vue';
import { getAllServiceRequests } from '../graphql/services/getAllServiceRequests';
import ServiceProviderDashboardItem from '../components/ServiceProviderDashboardItem.vue';
import { getAllUrgenciesRequest } from '../graphql/services/getAllUrgencies';
import { getIsUserLoggedIn } from '../services/user/getIsUserLoggedIn';

export default defineComponent({
  name: 'ServiceProviderDashboardPage',
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonTitle,
    IonList,
    MenuLeftContextAction,
    ServiceProviderDashboardItem,
  },
  data() {
    return {
      vehfixleServicesRows: [],
      vehfixleVendorLeads: [],
      urgencyTypes: [],
      reRenderKey: 0,
    };
  },
  async created() {
    if (!getIsUserLoggedIn()) {
      this.$router.push('/');
      return;
    }
    this.getAllUrgencies();
    this.getServiceRequests();
  },
  methods: {
    async getAllUrgencies() {
      this.urgencyTypes = await getAllUrgenciesRequest();
    },
    async getServiceRequests() {
      const res = await getAllServiceRequests();
      if (res) {
        this.vehfixleServicesRows = res.serviceRequests;
      }
    },
  },
  async ionViewWillEnter() {
    if (this.urgencyTypes === [] && this.vehfixleServicesRows === []) {
      this.getAllUrgencies();
      this.getServiceRequests();
    }
  },

});
</script>
