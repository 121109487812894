import { alertController } from '@ionic/vue';
import AlertConst from '../../constant/alert.constant';

const alertGeolocationPermissionIssue = () => new Promise((resolve) => {
  alertController
    .create({
      header: AlertConst.permissionAlert.geoLocationError.title,
      message: AlertConst.permissionAlert.geoLocationError.message,
      buttons: [
        {
          text: 'Ok',
          handler() {
            resolve(false);
          },
        },
      ],
    })
    .then((alert) => {
      alert.present();
    });
});

export { alertGeolocationPermissionIssue };
