import { App } from '@capacitor/app';
import state from '../../state/index';

const getAppInfo = async () => {
  if (state.isCapacitor) {
    const appInfo = await App.getInfo();
    return {
      appBuild: appInfo.build,
      appVersion: appInfo.version,
      appName: appInfo.name,
    };
  }
  // TODO: #67 I wonder if logic for getting non-capacitor app info could be moved here
  return {
    appBuild: '',
    appVersion: '',
    appName: '',
  };
};
export { getAppInfo };
