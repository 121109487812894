<template>
  <div>
    <div>
      <b>Requestor Email:</b> {{ vehfixleService.customer_email || "Not Available" }}
    </div>
    <div>
      <b>OBDII CODE:</b> {{ vehfixleService.obdii_code || "Not Available" }}
    </div>
    <div><b>Radius: </b>{{ vehfixleService.radius || "Not Available" }}</div>
    <div>
      <b>Request On:</b>
      {{ getFormattedDateTime(vehfixleService.request_date) }}
    </div>
    <div><b>Urgency: </b>{{ vehfixleService.urgency_name }}</div>
    <div><b>ZIP Code:</b> {{ vehfixleService.zip_code }}</div>
    <h2><b>Description:</b></h2>
    <div>{{ vehfixleService.request_description || "Not Available" }}</div>
    <br>
  </div>
</template>

<script>
import { getFormattedDateTime } from '../services/utilities/formatDateTime';

export default {
  name: 'RequesterInfo',
  props: {
    vehfixleService: {
      type: Object,
      required: true,
    },
    urgencyTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      getFormattedDateTime,
    };
  },
};
</script>
