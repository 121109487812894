import { createApp } from 'vue';
import VueCryptojs from 'vue-cryptojs';
import { IonicVue } from '@ionic/vue';
import state from './state';
import App from './App.vue';
import router from './router';

import './assets/css/custom.css';
/* icons */
import '@ionic/core/css/ionic.bundle.css';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

import { setApolloClient } from './apollo/client';
import { initializeSentry, registerVueSentry } from './services/error-handler/initializeSentry';
import capacitorService from './capacitor/capacitor.service';
import { getKeyValueFromLocalStorage } from './services/localstorage/getKeyValueFromLocalStorage';
import appConstant from './constant/app.constant';
/* Theme variables */
import './theme/variables.css';

initializeSentry();

const app = createApp(App).use(IonicVue, { swipeBackEnabled: false }).use(router)
  .use(VueCryptojs);

const setState = async () => {
  state.devMode = (getKeyValueFromLocalStorage('devModeEnabled') === 'true');
  if (!getKeyValueFromLocalStorage('apiHost')) {
    if (capacitorService.isCapacitorMobileApp) {
      state.apiUrl = appConstant.capacitorApiHost;
    } else {
      state.apiUrl = appConstant.apiHost;
    }
  } else {
    state.apiUrl = getKeyValueFromLocalStorage('apiHost');
  }
  state.isCapacitor = capacitorService.isCapacitorMobileApp;
};

const mountVueApp = () => {
  setApolloClient();
  app.mount('#app');
  /**
   * Register vue error handler
   */
  registerVueSentry(app);
};

capacitorService.setIsCapacitorMobileApp().then(setState).then(mountVueApp);
