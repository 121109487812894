const getVehicleMakesQuery = (year) => {
    const query = `
    query MyQuery {
        getVehicleMakes(vehicleYear: "${year}") {
          edges {
            node
          }
        }
      }
      `;
    return query;
  };
  
  export { getVehicleMakesQuery };
  
