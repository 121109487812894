import { getApolloClient } from "../../apollo/client";
import gql from "graphql-tag";
import { getServicesQuery } from "../query/getServices.query";

const getAllServicesRequest = async () => {
  try {
    const res = await getApolloClient().mutate({
      mutation: gql`
        ${getServicesQuery()}
      `,
    });
    return res.data.allServices.nodes;
  } catch (ex) {
    console.log(ex);
  }
};

export { getAllServicesRequest };
