const serviceProviderSchema = {
  type: "object",
  properties: {
    email: {
      type: "string",
    },
    password: {
      type: "string",
    },
    vendorName: {
      type: "string",
    },
    vendorAddress: {
      type: "string",
    },
    vendorCity: {
      type: "string",
    },
    vendorState: {
      type: "string",
    },
    vendorPhone: {
      type: "string",
    },
    vendorLocation: {
      type: "object",
    },
    vendorZip: {
      type: "string",
    },
    selectedRadius: {
      type: "integer",
    },
  },
  required: [
    "email",
    "password",
    "vendorName",
    "vendorAddress",
    "vendorCity",
    "vendorState",
    "vendorPhone",
    "vendorZip",
    "selectedRadius",
  ],
};
export { serviceProviderSchema };
