<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-back-button slot="start" mode="ios" default-href="/" />
        <ion-title>
          GET STARTED
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="onboarding-buttons">
        <ion-card>
          <div class="ion-padding-horizontal">
            <div class="ion-text-center">
              <ion-text>ARE YOU A:</ion-text>
              <br>
              <ion-button
                expand="block"
                class="hollow-button large-button"
                @click="goToServiceRequestDashboardPage"
              >
                CUSTOMER
              </ion-button>
            </div>
            <br>
            <ion-text class="ion-text-center">
              OR
            </ion-text>
            <br>
            <div class="ion-text-center">
              <ion-button
                expand="block"
                class="hollow-button large-button"
                @click="goToServiceProviderPage"
              >
                SERVICE PROVIDER
              </ion-button>
            </div>
          </div>
        </ion-card>
      </div>
    </ion-content>
    <img class="corner-logo" src="../../stuff/Icon/Vefixle_Monogram_White.png" alt="vefixle logo" />
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonButton,
  IonPage,
  IonToolbar,
  IonRow,
  IonCol,
  IonGrid,
  IonText,
  IonNote,
  IonCard,
  IonTitle,
  IonBackButton,
} from '@ionic/vue';
import MenuLeftContextAction from '../components/MenuLeftContextAction.vue';

export default defineComponent({
  name: 'OnboardingSelectPage',
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonButton,
    IonToolbar,
    IonText,
    IonRow,
    IonCol,
    IonGrid,
    MenuLeftContextAction,
    IonNote,
    IonCard,
    IonTitle,
    IonBackButton,
  },
  methods: {
    goToServiceRequestDashboardPage() {
      this.$router.push({
        path: '/service-request',
      });
    },
    goToServiceProviderPage() {
      this.$router.push({
        path: '/service-provider-onboarding',
      });
    },
  },
});
</script>

<style src="../assets/css/style.css" />
