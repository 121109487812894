<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/" mode="ios" />
        </ion-buttons>
        <IonTitle class="ion-text-center">
          LOGIN
        </IonTitle>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="login-card">
        <ion-card>
          <form id="login-form" :key="reRenderKey">
            {{ errorMessage }}
            <ion-item>
              <ion-label
                class="large-input"
                position="stacked"
              >
                Email
              </ion-label>
              <ion-input
                v-model="loginForm.email"
                mode="ios"
                placeholder="Enter Your Email"
                class="large-input"
                label="Email"
                label-placement="stacked"
                type="text"
              />
            </ion-item>
            <br>
            <ion-item>
              <ion-label
                position="stacked"
                class="large-input"
              >
                Password
              </ion-label>
              <ion-input
                v-model="loginForm.password"
                class="large-input"
                placeholder="Enter Your Password"
                type="password"
              />
            </ion-item>
            <br>
            <div>
              <ion-button
                v-if="!processingSubmit"
                expand="block"
                class="hollow-button login-button large-button"
                @click="onLogin()"
              >
                LOGIN
              </ion-button>
              <div class="ion-text-center ion-padding-vertical">
                <ion-note

                  @click="onForgotPassword()"
                >
                  Forgot Password?
                </ion-note>
              </div>
              <ion-spinner
                v-if="processingSubmit"
                name="circles"
              />
            </div>
            <br>
          </form>
        </ion-card>
      </div>
    </ion-content>
    <img class="corner-logo" src="../../stuff/Icon/Vefixle_Monogram_White.png" alt="vefixle logo" />
  </ion-page>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonInput,
  IonPage,
  IonContent,
  IonButtons,
  IonHeader,
  IonToolbar,
  IonButton,
  IonTitle,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonNote,
  IonGrid,
  IonRow,
  IonCol,
  IonBackButton,
  IonSpinner,
  alertController,
} from '@ionic/vue';
import { loginRequest } from '../graphql/services/loginRequest';
import MenuLeftContextAction from '../components/MenuLeftContextAction.vue';
import { getDashboard } from '../services/user/getDashboard';
import appConstant from '../constant/app.constant';

export default {
  name: 'LoginPage',
  components: {
    IonItem,
    IonLabel,
    IonInput,
    IonPage,
    IonContent,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonButton,
    IonTitle,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonNote,
    IonGrid,
    IonRow,
    IonCol,
    MenuLeftContextAction,
    alertController,
    IonBackButton,
    IonSpinner,
  },
  data() {
    return {
      loginForm: {
        email: null,
        password: null,
      },
      processingSubmit: false,
      errorMessage: '',
      reRenderKey: 0,
      appVersion: appConstant.application.version,
    };
  },
  ionViewWillEnter() {
    this.loginForm.email = null;
    this.loginForm.password = null;
    this.reRenderKey += 1;
  },
  methods: {
    onGetRegistered() {
      this.$router.push({ path: '/signUp' });
    },
    async callLoginRequest() {
      this.errorMessage = '';
      const res = await loginRequest(
        this.loginForm.email,
        this.loginForm.password,
      );
      // if we got a token then we are good to go
      if (res?.jwtToken) {
        return true;
      }
      // otherwise alert the user
      this.errorMessage = 'Invalid username and password';
      this.processingSubmit = false;
      return false;
    },
    async onLogin() {
      this.processingSubmit = true;
      const res = await this.callLoginRequest();
      console.log('here we go');
      if (res) {
        const dashboard = getDashboard();
        this.$router.push({ path: dashboard });
        this.processingSubmit = false;
      }
    },
    async onForgotPassword() {
      const forgotPasswordAlert = await alertController.create({
        header: 'Forgot Password?',
        message: 'If you have forgotten your password, please contact help@vefixle.com for futher assistance',
        buttons: ['OK'],
      });
      await forgotPasswordAlert.present();
    },
  },
};
</script>
