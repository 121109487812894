<template>
  <div>
    <div class="center-form">
      <ion-list class="form-list">
        <ion-item-divider>
          <ion-label> What type of service? <b>*</b> </ion-label>
        </ion-item-divider>
        <ion-item lines="none">
          <ion-select
            v-model="formData.selectedProblemCategory"
            interface="action-sheet"
            :interface-options="selectInterface"
            placeholder="Select Service Type"
            @ion-change="onCategoryChange"
          >
            <ion-select-option
              v-for="possibleProblemType in possibleProblemTypes"
              :key="possibleProblemType.serviceCategoryId"
              :value="possibleProblemType.serviceCategoryId"
            >
              {{ possibleProblemType.serviceCategory }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item-divider>
          <ion-label> Tell us about your vehicle:</ion-label>
        </ion-item-divider>
        <ion-item :class="{ error: errorsRequiredFields.selectedYear }">
          <ion-label position="stacked">
            Vehicle Year <b>*</b>
          </ion-label>
          <ion-select
            v-model="formData.selectedYear"
            interface="action-sheet"
            placeholder="Select Vehicle Year"
            :interface-options="selectInterface"
            @ion-change="onYearChange"
          >
            <ion-select-option
              v-for="years in yearsList"
              :key="years.node"
              :value="years.node"
            >
              {{ years.node }}
            </ion-select-option>
            {{ yearsList }}
          </ion-select>
        </ion-item>
        <ion-item
          v-if="possibleMakes.length > 0"
          :class="{ error: errorsRequiredFields.selectedMake }"
        >
          <ion-label position="stacked">
            Make <b>*</b>
          </ion-label>
          <ion-select
            v-model="formData.selectedMake"
            interface="action-sheet"
            placeholder="Select make"
            :interface-options="selectInterface"
            @ion-change="onMakeChange"
          >
            <ion-select-option
              v-for="possibleMake in possibleMakes"
              :key="possibleMake.node"
              :value="possibleMake.node"
            >
              {{ possibleMake.node }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item
          v-if="possibleModels.length > 0"
          :class="{ error: errorsRequiredFields.selectedModel }"
        >
          <ion-label position="stacked">
            Model <b>*</b>
          </ion-label>
          <ion-select
            v-model="formData.selectedModel"
            interface="action-sheet"
            placeholder="Select model"
            :interface-options="selectInterface"
            @ion-change="onModelChange"
          >
            <ion-select-option
              v-for="possibleModel in possibleModels"
              :key="possibleModel.node"
              :value="possibleModel.node"
            >
              {{ possibleModel.node }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item
          v-if="possibleTrims.length > 0 && possibleTrims[0].node != null"
          :class="{ error: errorsRequiredFields.selectedTrim }"
          lines="none"
        >
          <ion-label position="stacked">
            Trim <b>*</b>
          </ion-label>
          <ion-select
            v-model="formData.selectedTrim"
            interface="action-sheet"
            :interface-options="selectInterface"
            placeholder="Select trim"
            @ion-change="onTrimChange"
          >
            <ion-select-option
              v-for="possibleTrim in possibleTrims"
              :key="possibleTrim.node"
              :value="possibleTrim.node"
            >
              {{ possibleTrim.node }}
            </ion-select-option>
            <ion-select-option
              key="null"
              value="null"
            >
              Not Listed
            </ion-select-option>
          </ion-select>
        </ion-item>
      </ion-list>
    </div>
    <div v-if="vehicleSelected">
      <div class="center-form">
        <ion-list class="form-list">
          <ion-item-divider>
            <ion-label>Tell us about your problem.</ion-label>
          </ion-item-divider>
          <ion-item :class="{ error: errorsRequiredFields.selectedProblemType }">
            <ion-label
              position="stacked"
              class="font-size-1abel"
            >
              What problem are you having? <b>*</b>
            </ion-label>
            <ion-select
              v-model="formData.selectedProblemType"
              interface="action-sheet"
              placeholder="Select Issue"
            >
              <ion-select-option
                v-for="possibleProblem in possibleProblems"
                :key="possibleProblem.serviceId"
                :value="possibleProblem.serviceId"
              >
                {{ possibleProblem.serviceLabel }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item>
            <ion-label
              :class="{ error: errorsRequiredFields.zipCode }"
              position="stacked"
            >
              What is your zip code? <b>*</b>
            </ion-label>
            <ion-input
              v-model="formData.zipCode"
              mode="ios"
              type="number"
              placeholder="Enter your zip code"
              @ion-change="onzipChange"
            />
            <br>
            <div>
              <ion-button class="small-button" @click="askForCurentLocation">
                <ion-icon
                  slot="icon-only"
                  :ios="locateOutline"
                  :md="locateOutline"
                />
                &nbsp;USE MY LOCATION
              </ion-button>
              <ion-text
                v-if="locFound"
                class="ion-padding-horizontal"
                color="success"
              >
                Location Fetched
              </ion-text>
              <ion-text
                v-if="!locFound"
                class="ion-padding-horizontal"
                color="danger"
              >
                Location Not Fetched
              </ion-text>
            </div>
          </ion-item>

          <ion-item :class="{ error: errorsRequiredFields.selectedRadius }">
            <ion-label position="stacked">
              Radius <b>*</b>
            </ion-label>
            <ion-select
              v-model="formData.selectedRadius"
              interface="action-sheet"
              placeholder="Select"
            >
              <ion-select-option
                v-for="possibleRadiusValue in possibleRadiusValues"
                :key="possibleRadiusValue.id"
                :value="possibleRadiusValue.id"
              >
                {{ possibleRadiusValue.value }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <!-- <ion-item :class="{ error: errorsRequiredFields.additionalComments }">
          <ion-label position="stacked">
            Do you have a code from an OBDII reader?
          </ion-label>
          <ion-input
            v-model="formData.OBDIIReaderCode"
            placeholder="Enter code here"
          />
        </ion-item> -->
          <ion-item :class="{ error: errorsRequiredFields.additionalComments }">
            <ion-label position="stacked">
              Any additional information? <b>*</b>
            </ion-label>
            <ion-textarea
              v-model="formData.additionalComments"
              rows="5"
              placeholder="Please add any information you'd like to share with the mechanic. Include a code from an OBDII reader if you have one."
            />
          </ion-item>
          <ion-item>
            <ion-label position="stacked">
              How urgent is your problem? <b>*</b>
            </ion-label>
            <ion-select
              v-model="formData.selectedUrgencyType"
              interface="action-sheet"
              placeholder="Select"
            >
              <ion-select-option
                v-for="urgencyType in urgencyTypes"
                :key="urgencyType.node.urgencyId"
                :value="urgencyType.node.urgencyId"
              >
                {{ urgencyType.node.urgencyName }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item v-if="!getIsUserLoggedIn()" lines="none">
            <ion-label position="stacked">
              What is your email address? <b>*</b>
            </ion-label>
            <ion-input
              v-model="formData.email"
              placeholder="Enter email"
            />
          </ion-item>
        </ion-list>
      </div>
      <br>
      <div class="ion-text-center">
        <ion-button
          v-if="!processingSubmit"
          @click="submitServiceRequest()"
        >
          SUBMIT REQUEST
        </ion-button>
        <ion-spinner
          v-if="processingSubmit"
          name="circles"
        />
      </div>
      <br>
    </div>
  </div>
</template>

<script>
import Ajv from 'ajv';
import {
  IonSelect,
  IonSelectOption,
  IonList,
  IonLabel,
  IonItem,
  IonTextarea,
  IonItemDivider,
  IonButton,
  IonIcon,
  IonInput,
  alertController,
  modalController,
  IonSpinner,
  IonCard,
  IonText,
} from '@ionic/vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import { locateOutline, trophy } from 'ionicons/icons';
import { getCurrentLocation } from '../services/capacitor/geoLocation.service';
import { createCustomerRequest } from '../graphql/services/createCustomerRequest';
import { createServiceRequest } from '../graphql/services/createServiceRequest';
import { serviceRequestSchema } from '../graphql/schema/serviceRequestSchema';
import { RADIUS_VALUES } from '../constant/service-request/radius.constant';
import { getAllServicesRequest } from '../graphql/services/getAllServices';
import { getAllUrgenciesRequest } from '../graphql/services/getAllUrgencies';
import UpdatePasswordScreen from './UpdatePasswordScreen.vue';
import { getIsUserLoggedIn } from '../services/user/getIsUserLoggedIn';
import { getVehicleYearsRequest } from '../graphql/services/getVehicleYearsRequest';
import { getVehicleMakesRequest } from '../graphql/services/getVehicleMakesRequest';
import { getVehicleModelsRequest } from '../graphql/services/getVehicleModelsRequest';
import { getVehicleTrimsRequest } from '../graphql/services/getVehicleTrimsRequest';
import { getZipCodeRequest } from '../graphql/services/getZipCodeRequest';
import { getServiceCategoryRequest } from '../graphql/services/getServiceCategory';

export default {
  name: 'ServiceRequestPage',
  components: {
    IonSelect,
    IonSelectOption,
    IonLabel,
    IonList,
    IonItem,
    IonTextarea,
    IonItemDivider,
    IonButton,
    IonIcon,
    IonInput,
    IonSpinner,
    IonCard,
    IonText,
  },
  props: {
    renderKey: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selectInterface: { side: 'top' },
      formData: {
        email: null,
        zipCode: null,
        requestLoc: {
          x: null,
          y: null,
        },
        OBDIIReaderCode: null,
        vinNo: null,
        selectedUrgencyType: null,
        selectedProblemCategory: null,
        selectedProblemType: null,
        selectedRadius: null,
        selectedYear: null,
        selectedMake: null,
        selectedModel: null,
        selectedTrim: null,
        additionalComments: null,
      },
      locateOutline,
      possibleProblemTypes: '',
      possibleProblems: '',
      urgencyTypes: '',
      possibleModels: '',
      possibleMakes: '',
      possibleTrims: '',
      possibleRadiusValues: RADIUS_VALUES,
      year: 1950,
      isLocationClicked: false,
      errorsRequiredFields: {},
      yearsList: [2024],
      processingSubmit: false,
      vehicleSelected: false,
      locFound: false,
      fetchedLoc: null,
    };
  },
  async mounted() {
    this.possibleProblemTypes = await getServiceCategoryRequest();
    if (this.renderKey === 1) {
      await this.getAllUrgencies();
      await this.getYear();
    }
  },
  methods: {
    getIsUserLoggedIn() {
      return getIsUserLoggedIn();
    },
    onCategoryChange() {
      console.log(this.formData.selectedProblemCategory);
      this.getAllServices(this.formData.selectedProblemCategory);
    },
    async getAllServices(id) {
      const services = await getAllServicesRequest();
      this.possibleProblems = services.filter((service) => service.serviceCategoryId === id);
    },
    async getAllUrgencies() {
      this.urgencyTypes = await getAllUrgenciesRequest();
      return this.urgencyTypes;
    },
    async showFormInvalid() {
      const alert = await alertController.create({
        header: 'Please fill out the following fields: \n',
        message: this.buildErrorString(),
        buttons: ['OK'],
      });

      await alert.present();
    },
    async askForCurentLocation() {
      const userLocation = await getCurrentLocation();
      if (userLocation) {
        this.formData.requestLoc = {
          x: userLocation.coords.latitude,
          y: userLocation.coords.longitude,
        };
        this.formData.zipCode = await getZipCodeRequest(
          this.formData.requestLoc.x,
          this.formData.requestLoc.y,
        );
        this.fetchedLoc = this.formData.zipCode;
        this.locFound = true;
      }
    },
    onzipChange() {
      if (this.formData.zipCode !== this.fetchedLoc) {
        this.locFound = false;
        this.formData.requestLoc.x = null;
        this.formData.requestLoc.y = null;
      }
    },
    async getYear() {
      this.yearsList = await getVehicleYearsRequest();
      return this.yearsList;
    },
    async onYearChange() {
      this.formData.selectedMake = '';
      this.formData.selectedModel = '';
      this.formData.selectedTrim = '';
      this.vehicleSelected = false;
      this.possibleMakes = await getVehicleMakesRequest(this.formData.selectedYear);
      return this.possibleMakes;
    },
    async onMakeChange() {
      this.formData.selectedModel = '';
      this.formData.selectedTrim = '';
      this.vehicleSelected = false;
      this.possibleModels = await getVehicleModelsRequest(
        this.formData.selectedYear,
        this.formData.selectedMake,
      );
      return this.possibleMakes;
    },
    async onModelChange() {
      this.formData.selectedTrim = '';
      this.vehicleSelected = false;
      this.possibleTrims = await getVehicleTrimsRequest(
        this.formData.selectedYear,
        this.formData.selectedMake,
        this.formData.selectedModel,
      );
      for (let i = 0; i < this.possibleTrims.length; i += 1) {
        if (this.possibleTrims[i].node == null) {
          this.possibleTrims.splice(i, 1);
        }
      }
      this.vehicleSelected = false;
      if (this.formData.selectedTrim !== '' || this.possibleTrims.length < 1) {
        this.vehicleSelected = true;
      }
      return this.possibleTrims;
    },
    async onTrimChange() {
      this.vehicleSelected = true;
    },
    navigateToServiceStatusPage(serviceRequestId) {
      this.$router.push({
        path: `/category/${this.$route.params.categoryId}/service-request/${serviceRequestId}`,
      });
    },
    validateServiceRequest() {
      this.errorsRequiredFields = {};
      const mutationFields = Object.keys(this.formData).reduce((acc, item) => {
        if (this.formData[item] !== null) {
          acc[item] = this.formData[item];
        }
        return acc;
      }, {});
      if (!mutationFields.requestLoc.x) {
        delete mutationFields.requestLoc;
      }
      const ajv = new Ajv({ allErrors: true });
      const validate = ajv.compile(serviceRequestSchema);
      const valid = validate(mutationFields);
      if (validate.errors) {
        this.errorsRequiredFields = validate.errors.reduce((acc, item) => {
          if (item.params.missingProperty) {
            acc[item.params.missingProperty] = true;
          }
          if (acc.additionalComments) {
            if (this.formData.OBDIIReaderCode != null || this.formData.additionalComments != null) {
              delete acc.additionalComments;
            }
          }
          return acc;
        }, {});
      }
      return valid;
    },
    async showReturningUserModal() {
      const modal = await modalController.create({
        component: UpdatePasswordScreen,
      });
      modal.present();
    },
    buildErrorString() {
      const errs = this.errorsRequiredFields;
      let message = '';
      let additionalComments = '';
      let zip = '';
      let radius = '';
      let problem = '';
      let email = '';
      if (errs.additionalComments) {
        additionalComments = 'OBDII Code OR Describe Request, <br>';
      }
      if (errs.zipCode) {
        zip = 'Zip Code, <br>';
      }
      if (errs.selectedRadius) {
        radius = 'Desired Service Radius, <br>';
      }
      if (errs.selectedProblemType) {
        problem = 'Selected Problem Category, <br>';
      }
      if (errs.email) {
        email = 'Email Address, <br>';
      }
      message = additionalComments + zip + radius + problem + email;
      return message;
    },
    async submitServiceRequest() {
      if (this.validateServiceRequest()) {
        this.processingSubmit = true;
        try {
          let serviceRequestId;
          if (!getIsUserLoggedIn()) {
            // onboard customer
            serviceRequestId = await createCustomerRequest(this.formData);
          } else {
            serviceRequestId = await createServiceRequest(this.formData);
          }

          this.navigateToServiceStatusPage(serviceRequestId);

          this.processingSubmit = false;
        } catch (err) {
          this.processingSubmit = false;
          // TODO: #91 don't leave a string constant here
          if (err.message === 'Looks like you have already registered, Please sign in') {
            const InvalidRequestalert = await alertController.create({
              header: 'Returning User',
              message:
                "We've seen a request from you before, but you did not create a password. Please contact help@vefixle.com for assistance.",
              buttons: ['OK'],
            });

            await InvalidRequestalert.present();
          } else {
            console.log(err);
            const InvalidRequestalert = await alertController.create({
              header: 'Request Failed!',
              message:
                'Sorry, your request failed, please contact us at help@vefixle.com.',
              buttons: ['OK'],
            });

            await InvalidRequestalert.present();
          }
        }
      } else {
        this.processingSubmit = false;
        this.showFormInvalid();
      }
    },
  },
};
</script>
