<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <MenuLeftContextAction />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="first-time-user-card">
        <ion-card>
          <div>
            <img
              src="../../stuff/Logo/Vefixle_Main_Logo_Black-BG.png"
              alt=""
            >
            <ion-text id="tagline">
              AUTO REPAIR REIMAGINED
            </ion-text>
          </div>
          <div class="ion-padding-horizontal">
            <div class="ion-text-center">
              <ion-button
                id="home-login-button"
                expand="block"
                class="hollow-button large-button"
                @click="onLogin"
              >
                LOGIN
              </ion-button>
              <br>
              <ion-button
                class="large-button"
                expand="block"
                @click="onGetStarted"
              >
                GET STARTED
              </ion-button>
            </div>
          </div>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonButton,
  IonPage,
  IonToolbar,
  IonRow,
  IonCol,
  IonGrid,
  IonText,
  IonNote,
  IonCard,
} from '@ionic/vue';
import state from '../state';
import MenuLeftContextAction from '../components/MenuLeftContextAction.vue';
import { getIsUserLoggedIn } from '../services/user/getIsUserLoggedIn';
import appConstant from '../constant/app.constant';

export default defineComponent({
  name: 'FirstTimeUserPage',
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonButton,
    IonToolbar,
    IonText,
    IonRow,
    IonCol,
    IonGrid,
    MenuLeftContextAction,
    IonNote,
    IonCard,
  },
  setup() {
    return {
      state,
    };
  },
  data() {
    return {
      userLoggedId: getIsUserLoggedIn(),
      vehfixleCategoriesRows: [],
      appVersion: appConstant.application.version,
      bundleVersion: appConstant.application.bundleVersion,
      devModeEnabled: state.devMode,
    };
  },
  mounted() {
    this.devModeEnabled = state.devMode;
  },
  methods: {
    onLogin() {
      this.$router.push({
        path: '/login',
      });
    },
    onGetStarted() {
      this.$router.push({
        path: '/getStarted',
      });
    },
  },
});
</script>

<style src="../assets/css/style.css" />
