import { getApolloClient } from "../../apollo/client";
import gql from "graphql-tag";
import { getAllServiceRequestsQuery } from "../query/getServiceRequests.query";

const getAllServiceRequests = async () => {
  try {
    let res = await getApolloClient().mutate({
      mutation: gql`
        ${getAllServiceRequestsQuery()}
      `,
    });
    res = res.data?.getServiceRequests;
    return {
      serviceRequests: res,
    };
  } catch (ex) {
    console.log(ex);
  }
};

export { getAllServiceRequests };
