<template>
  <div class="dashboard-item-container">
    <ion-card class="dashboard-card">
      <ion-card-content>
        <IonItem
          lines="none"
          @click="changeExpansion()"
        >
          <IonText class="request-header">
            <b>Request ID: {{ vehfixleService.service_request_uuid }}</b> <br>
            Date: {{ requestDate }}
          </IonText>
          <ion-icon
            v-if="expanded"
            slot="end"
            :ios="chevronUpCircleOutline"
            :md="chevronUpCircleOutline"
            color="white"
            size="large"
          />
          <ion-icon
            v-if="!expanded"
            slot="end"
            :ios="chevronDownCircleOutline"
            :md="chevronDownCircleOutline"
            color="white"
            size="large"
          />
        </IonItem>
        <ion-item v-if="expanded" lines="none">
          <div>
            <IonText color="dark">
              <h2><b>Service Providers Info:</b></h2>
            </IonText>
            <hr class="hr-rule">
            <div
              v-for="(item, idx) in vehfixleService.vendor_leads"
              :key="item"
            >
              <IonText color="dark">
                <h2>Vendor {{ idx + 1 }}</h2>
              </IonText>
              <VendorLeadsInfo :vendor-lead="item" />
            </div>
            <br>
            <IonText color="dark">
              <h2><b>Vehicle Info:</b></h2>
            </IonText>
            <hr class="hr-rule">
            <VehicleInfo
              v-if="vehfixleService"
              :vehicle="vehfixleService"
            />
          </div>
        </ion-item>
      </ion-card-content>
    </ion-card>
  </div>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonItemDivider,
  IonText,
  IonNote,
  IonCardHeader,
  IonTitle,
  IonIcon,
  IonItem,
} from '@ionic/vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  chevronUpCircleOutline,
  chevronDownCircleOutline,
} from 'ionicons/icons';
import { defineComponent } from 'vue';
import VehicleInfo from './VehicleInfo.vue';
import VendorLeadsInfo from './VendorLeadsInfo.vue';
import RequesterInfo from './RequestorInfo.vue';
import advertisementItem from './AdvertisementItem.vue';

export default defineComponent({
  name: 'CustomerDashboardItem',
  components: {
    IonCard,
    IonCardContent,
    IonText,
    IonItemDivider,
    IonNote,
    VehicleInfo,
    VendorLeadsInfo,
    RequesterInfo,
    advertisementItem,
    IonCardHeader,
    IonTitle,
    IonIcon,
    IonItem,
  },
  props: {
    vehfixleService: {
      type: Object,
      required: true,
    },
    urgencyTypes: {
      type: Array,
      required: true,
    },
    adInfo: {
      type: Object,
      required: false,
      default: null,
    },
    serviceIndex: {
      type: Number,
      required: false,
      default: null,
    },
  },
  setup() {
    return {
      chevronUpCircleOutline,
      chevronDownCircleOutline,
    };
  },
  data() {
    return {
      adData: null,
      requestDate: '',
      expanded: false,
    };
  },
  computed: {
    getVendorLead() {
      return (
        this.vehfixleService.vendor_leads
      );
    },
  },
  async created() {
    this.checkAdIndex();
    this.requestDate = new Date(this.vehfixleService.request_date).toDateString();
  },
  methods: {
    changeExpansion() {
      this.expanded = !this.expanded;
    },
    checkAdIndex() {
      if (this.adInfo[this.serviceIndex]) {
        this.adData = this.adInfo[this.serviceIndex];
      }
    },
  },
});
</script>
