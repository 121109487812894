import { getApolloClient } from "../../apollo/client";
import gql from "graphql-tag";
import { createCustomerMutation } from "../mutation/createCustomer.mutation";
import AppConst from "../../constant/app.constant";
import LocalStorageConst from "../../constant/localstorage.constant";
import { setKeyValueInLocalStorage } from "../../services/localstorage/setKeyValueInLocalStorage";
import { setUserUc } from "../../services/user/setUserUc";
const createCustomerRequest = async (formData) => {
  try {
    const res = await getApolloClient().mutate({
      mutation: gql`
        ${createCustomerMutation(formData)}
      `,
    });
    if (res.data.onboardCustomer.result.jwtToken) {
      if (res.data.onboardCustomer.result.jwtToken) {
        setUserUc({
          memberId: res.data.onboardCustomer.result.memberId,
          email: formData.email,
          password: formData.password || AppConst.tempPass
        })
        setKeyValueInLocalStorage(
          LocalStorageConst.authToken,
          res.data.onboardCustomer.result.jwtToken
        );
      }
    }
    const vendorLeads = res.data.onboardCustomer.result.vendorLeads
    setKeyValueInLocalStorage(LocalStorageConst.vendorLeadByServiceId + res.data.onboardCustomer.result.serviceRequestId, JSON.stringify(vendorLeads));

    return res.data.onboardCustomer.result.serviceRequestId;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export { createCustomerRequest };
