import { getApolloClient } from "../../apollo/client";
import gql from "graphql-tag";
import { getVehicleYearsQuery } from "../query/getVehicleYears.query";

const getVehicleYearsRequest = async () => {
  try {
    const res = await getApolloClient().mutate({
      mutation: gql`
        ${getVehicleYearsQuery()}
      `,
    });
    return res.data.getVehicleYears.edges;
  } catch (ex) {
    console.log(ex);
  }
};

export { getVehicleYearsRequest };
