const getAdsMutation = () => {
    const query = `mutation MyMutation {
        getAds(input: {stateFilter: ["PA","CO"]}) {
          adJson
        }
      }`;
    return query;
  };
  
  export { getAdsMutation };
  