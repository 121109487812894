export default {
  platform: {
    web: 'web',
    ios: 'ios',
    android: 'android',
  },
  model: {
    iPhone: 'iPhone',
    iPad: 'iPad',
  },
};
