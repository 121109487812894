import gql from 'graphql-tag';
import LocalStorageConst from '../../constant/localstorage.constant';
import { getApolloClient } from '../../apollo/client';
import { setKeyValueInLocalStorage } from '../../services/localstorage/setKeyValueInLocalStorage';
import { authenticateMutation } from '../mutation/authenticate.mutation';
import { setUserUc } from '../../services/user/setUserUc';
import { getUserRoleRequest } from './getRoleRequest';

const loginRequest = async (email, password) => {
  try {
    const res = await getApolloClient().mutate({
      mutation: gql`
        ${authenticateMutation(email, password)}
      `,
    });
    if (res.data.authenticate.jwtToken) {
      setKeyValueInLocalStorage(LocalStorageConst.authToken, res.data.authenticate.jwtToken);
      setKeyValueInLocalStorage(LocalStorageConst.actualLogin, true);
      const userRole = await getUserRoleRequest();
      setUserUc({
        email,
        password,
        userRole,
      });
      return res.data.authenticate;
    }
    return false;
  } catch (ex) {
    console.log(ex);
    return false;
  }
};

export { loginRequest };
