const updatePasswordMutation = (memberId, password) => {
  const query = `mutation UpdatePasswordMutation {
    updatePass(input: {memberid: ${memberId}, newPassword: "${password}"}) {
      clientMutationId
    }
  }`;
  return query;
};

export { updatePasswordMutation };
