<template>
  <div class="vehicle-container">
    <div>
      <div><b>Name: </b>{{ vendorName }}</div>
      <div><b>Radius:</b>{{ radius }} mile(s)</div>
      <div><b>Address: </b>{{ address }}</div>
      <div><b>City: </b>{{ city }}</div>
      <div><b>State: </b>{{ vendorState }}</div>
      <div><b>Zip Code: </b>{{ vendorZip }}</div>
      <div><b>Contact Detail: </b>{{ PhoneNumber }}</div>
      <div>
        <b>Lead Date: </b>{{ leadDate }}
      </div>
    </div>
  </div>
</template>

<script>
import { getFormattedDateTime } from '../services/utilities/formatDateTime';

export default {
  props: {
    vendorLead: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      getFormattedDateTime,
    };
  },
  computed: {
    vendorName() {
      return this.formatValue(this.vendorLead.vendor[0].vendor_name);
    },
    vendorZip() {
      return this.formatValue(this.vendorLead.vendor[0].vendor_zip);
    },
    vendorState() {
      if (this.vendorLead.vendor[0].vendor_state === 'undefined') {
        return 'Not Available';
      }
      return this.formatValue(this.vendorLead.vendor[0].vendor_state);
    },
    PhoneNumber() {
      return this.formatValue(this.vendorLead.vendor[0].phone_number);
    },
    radius() {
      return this.formatValue(this.vendorLead.vendor[0].vendor_radius);
    },
    address() {
      const value = this.vendorLead.vendor[0].vendor_address;

      return this.formatValue(value);
    },
    city() {
      const value = this.vendorLead.vendor[0].vendor_city;
      return this.formatValue(value);
    },
    leadDate() {
      const value = this.vendorLead.lead_date
        ? getFormattedDateTime(this.vendorLead.lead_date)
        : '';
      return this.formatValue(value);
    },
  },
  methods: {
    formatValue(value) {
      return value || 'Not Available';
    },
  },
};
</script>
