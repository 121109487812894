const getServiceCategoryQuery = () => {
    const query = `
    query getAllServiceCategories {
      allServiceCategories {
        nodes {
          serviceCategoryId
          serviceCategory
        }
      }
    }`;
    return query;
  };
  
  export { getServiceCategoryQuery };
  