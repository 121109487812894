import appInfo from '../app.info.json';

export default {
  application: {
    name: 'Vefixle',
    version: appInfo.version,
    bundleVersion: appInfo.bundleVersion,
  },
  tempPass: 'vfixle-1234',
  dc1: '',
  apiHost: 'https://api.vefixle.com/',
  capacitorApiHost: 'https://api.vefixle.com/',
  devModeEnabled: true,
  dc2: 'vf1xl',
};
