import { createRouter, createWebHashHistory } from '@ionic/vue-router';
import { getDashboard } from '../services/user/getDashboard';
import { getIsUserLoggedIn } from '../services/user/getIsUserLoggedIn';
import LoginPage from '../views/LoginPage.vue';
import ServiceRequestCreatePage from '../views/ServiceRequestCreatePage.vue';
import ServiceRequestStatusPage from '../views/ServiceRequestStatusPage.vue';
import ServiceProviderOnboardingPage from '../views/ServiceProviderOnboardingPage.vue';
import FirstTimeUserPage from '../views/FirstTimeUserPage.vue';
import ServiceProviderDashboardPage from '../views/ServiceProviderDashboardPage.vue';
import CustomerDashboardPage from '../views/CustomerDashboardPage.vue';
import DevPage from '../views/Dev.vue';
import AccountPage from '../views/Account.vue';
import AboutUsPage from '../views/AboutUs.vue';
import GetStartedPage from '../views/OnboardingSelectPage.vue';

function decideHomePage() {
  if (getIsUserLoggedIn()) {
    const dashboard = getDashboard();
    if (dashboard === '/customer-dashboard') {
      return CustomerDashboardPage;
    }
    if (dashboard === '/service-provider-dashboard') {
      return ServiceProviderDashboardPage;
    }
  }
  return FirstTimeUserPage;
}

const routes = [
  {
    path: '',
    component: decideHomePage(),
  },
  {
    path: '/first-time-user',
    redirect: '/',
  },
  {
    path: '/customer-dashboard',
    component: CustomerDashboardPage,
  },
  {
    path: '/service-provider-dashboard',
    component: ServiceProviderDashboardPage,
  },
  {
    path: '/login',
    component: LoginPage,
  },
  {
    path: '/service-request',
    component: ServiceRequestCreatePage,
  },
  {
    path: '/category/:categoryId/service-request/:serviceRequestId',
    component: ServiceRequestStatusPage,
  },
  {
    path: '/service-provider-onboarding',
    component: ServiceProviderOnboardingPage,
  },
  {
    path: '/dev',
    component: DevPage,
  },
  {
    path: '/account',
    component: AccountPage,
  },
  {
    path: '/about',
    component: AboutUsPage,
  },
  {
    path: '/getStarted',
    component: GetStartedPage,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
