<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <MenuLeftContextAction v-if="userLoggedId" slot="start" />
        <ion-title class="ion-text-center">
          SERVICE REQUEST
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div
        v-if="vendorLeads !== null && vendorLeads.length > 0"
        class="ion-text-center"
      >
        <div>
          <h5>Your request is submitted!</h5>
        </div>
        <ion-card>
          <ion-card-header>
            <ion-card-title class="status-text">
              Here's who we sent your request to:
            </ion-card-title>
          </ion-card-header>
          <ion-card-content class="status-text">
            <ion-row class="border-bottom">
              <ion-col class="ion-text-left">
                <IonText color="light">
                  <h2>Name</h2>
                </IonText>
              </ion-col>
              <ion-col>
                <IonText color="light">
                  <h2>Distance</h2>
                </IonText>
              </ion-col>
              <ion-col>
                <IonText color="light">
                  <h2>Phone No.</h2>
                </IonText>
              </ion-col>
            </ion-row>
            <ion-row
              v-for="vendorLead in vendorLeads"
              :key="vendorLead.vendordId"
              class="border-bottom"
            >
              <ion-col class="ion-text-left">
                {{ vendorLead.vendor_name }}
              </ion-col>
              <ion-col> {{ vendorLead.request_distance || "N/A" }} </ion-col>
              <ion-col> {{ vendorLead.phone_number || "N/A" }} </ion-col>
            </ion-row>
          </ion-card-content>
        </ion-card>
        <br>
      </div>
      <div
        v-else
        class="ion-text-center"
      >
        <div>
          <h4>You hit a pothole as we build out our network.</h4>
        </div>
        <div>
          <h4>Don't worry, we're going to ensure you are taken care of!</h4>
        </div>
        <br/>
        <div>
          <h4>We're working on matching you with a few service providers near you now.</h4>
        </div>
        <div>
          <ion-card>
            <ion-card-content>
              Please contact us at help@vefixle.com for further assistance.
            </ion-card-content>
          </ion-card>
        </div>
      </div>
      <ion-card
        v-if="!userLoggedId"
        class="ion-text-center"
      >
        <IonCardHeader>
          <IonCardTitle color="light">
            Would you like to create an account?
          </IonCardTitle>
        </IonCardHeader>
        <ion-card-content>
          <ion-item>
            <ion-label position="stacked">
              Password:
            </ion-label>
            <ion-input
              v-model="newUserPassword"
              type="password"
              placeholder="Enter password"
            />
          </ion-item>
          <ion-button @click="onCreateAccount()">
            Create Account!
          </ion-button>
        </ion-card-content>
      </ion-card>
      <div class="ion-text-center">
        <ion-button
          v-if="userLoggedId"
          @click="navToDashboard()"
        >
          Continue To Dashboard
        </ion-button>
      </div>
      <script> gtag('event', 'conversion', {'send_to': 'AW-11476009077/IcDECNfZ0okZEPWAmOAq'}); </script>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonLabel,
  IonInput,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonItem,
  IonRow,
  IonCol,
  IonText,
  IonBackButton,
} from '@ionic/vue';
import MenuLeftContextAction from '../components/MenuLeftContextAction.vue';
import LocalStorageConst from '../constant/localstorage.constant';
import { getKeyValueFromLocalStorage } from '../services/localstorage/getKeyValueFromLocalStorage';
import { removeKeyValueFromLocalStorage } from '../services/localstorage/removeKeyValueFromLocalStorage';
import { getIsUserLoggedIn } from '../services/user/getIsUserLoggedIn';
import { getUserUc } from '../services/user/setUserUc';
import { updatePasswordRequest } from '../graphql/services/updatePasswordRequest';
import { loginRequest } from '../graphql/services/loginRequest';

export default {
  name: 'ServiceRequestStatusPage',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    MenuLeftContextAction,
    IonButton,
    IonLabel,
    IonInput,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonItem,
    IonRow,
    IonCol,
    IonText,
    IonBackButton,
  },
  data() {
    return {
      serviceRequestId: 0,
      vendorLeads: [],
      userLoggedId: getIsUserLoggedIn(),
      newUserPassword: null,
    };
  },
  async mounted() {
    this.getVendorLeadByServiceId();
  },
  methods: {
    async getVendorLeadByServiceId() {
      const { params } = this.$route;
      this.serviceRequestId = Number(params.serviceRequestId);
      const leadsFromLs = getKeyValueFromLocalStorage(
        LocalStorageConst.vendorLeadByServiceId + this.serviceRequestId,
      );
      removeKeyValueFromLocalStorage(
        LocalStorageConst.vendorLeadByServiceId + this.serviceRequestId,
      );
      if (leadsFromLs) {
        this.vendorLeads = JSON.parse(leadsFromLs);
      }
    },
    navToDashboard() {
      this.$router.push({ path: '/customer-dashboard' });
    },
    onCreateAccount() {
      const userObject = getUserUc();
      if (this.newUserPassword && userObject) {
        updatePasswordRequest(userObject.memberId, this.newUserPassword).then(() => {
          loginRequest(userObject.email, this.newUserPassword).then((status) => {
            console.log(status);
            this.userLoggedId = true;
          });
        });
      } else {
        // TODO: #70 Need to handle this case where password add fails.
      }
    },
  },
};
</script>
