import * as Sentry from '@sentry/browser';
import appConstant from '../../constant/app.constant'
export const registerVueSentry = (app) => {
  app.config.errorHandler = (error) => {
    console.log(error);
    Sentry.captureException(error);
  };
};

export const initializeSentry = async () => {
  Sentry.init({
    logErrors: true,
    release: 'vfixl',
    dsn: 'https://938f1708bb254949a0236ceab151aca7@sentry.io/6730487',
    // transport: Sentry.Transports.FetchTransport,
    // integrations: [new Sentry.Integrations.Vue()],
    environment: "dev",
  });
  Sentry.configureScope((scope) => {
    // TODO: #68 How is appversion being used here?
    scope.setTag('appversion', appConstant.application.version);
  });
};
