const serviceRequestSchema = {
  type: "object",
  properties: {
    email: {
      type: "string",
      minLength: 1,
    },
    zipCode: {
      type: "string",
    },
    requestLoc: {
      type: "object",
      properties: {
        x: {
          type: "number",
        },
        y: {
          type: "number",
        },
      },
    },
    ODBIIReaderCode: {
      type: "string",
    },
    vinNo: {
      type: "string",
    },
    selectedUrgencyType: {
      type: "integer",
    },
    selectedProblemType: {
      type: "integer",
    },
    selectedRadius: {
      type: "integer",
    },
    selectedYear: {
      type: "string",
    },
    selectedMake: {
      type: "string",
    },
    selectedModel: {
      type: "string",
    },
    selectedTrim: {
      type: "string",
    },
    additionalComments: {
      type: "string",
    },
  },
  required: [
    "zipCode",
    "selectedProblemType",
    "selectedRadius",
    "selectedYear",
    "selectedMake",
    "selectedModel",
    "selectedTrim",
    "additionalComments",
  ],
};
export { serviceRequestSchema };
