const authenticateMutation = (email, password) => {
  const query = `mutation Auth {
    authenticate(input: {email: "${email}", userPassword: "${password}"}) {
      clientMutationId
      jwtToken
      query {
        getMemberId,
        getRoleJwt
      }
    }
  }`;
  return query;
};

export { authenticateMutation };
