<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <MenuLeftContextAction />
        </ion-buttons>
        <IonTitle>DASHBOARD</IonTitle>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <CustomerDashboardItem
        v-for="(vehfixleService, index) in vehfixleServicesRows"
        :key="index"
        :vehfixle-service="vehfixleService"
        :urgency-types="urgencyTypes"
        :ad-info="adInfo"
        :service-index="index"
      />
      <advertisementItem :ad-data="adInfo" />
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonTitle,
  IonCol,
  IonRow,
  IonGrid,
} from '@ionic/vue';
import MenuLeftContextAction from '../components/MenuLeftContextAction.vue';
import { getAllServiceRequests } from '../graphql/services/getAllServiceRequests';
import { getAllUrgenciesRequest } from '../graphql/services/getAllUrgencies';
import CustomerDashboardItem from '../components/CustomerDashboardItem.vue';
import { getAdRequest } from '../graphql/services/getAdRequest';
import advertisementItem from '../components/AdvertisementItem.vue';
import { getIsUserLoggedIn } from '../services/user/getIsUserLoggedIn';

export default defineComponent({
  name: 'CustomerDashboardPage',
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonTitle,
    IonCol,
    IonRow,
    IonGrid,
    MenuLeftContextAction,
    CustomerDashboardItem,
    advertisementItem,
  },
  data() {
    return {
      vehfixleServicesRows: [],
      vehfixleVendorLeads: [],
      urgencyTypes: [],
      adInfo: null,
    };
  },
  async created() {
    if (!getIsUserLoggedIn()) {
      this.$router.push('/');
      return;
    }
    this.adInfo = await getAdRequest();
    await this.getAllUrgencies();
    await this.getServiceRequests();
  },
  methods: {
    async getAllUrgencies() {
      this.urgencyTypes = await getAllUrgenciesRequest();
    },
    async getServiceRequests() {
      const res = await getAllServiceRequests();
      if (res) {
        this.vehfixleServicesRows = res.serviceRequests;
      }
    },
  },
  async ionViewWillEnter() {
    if (this.adInfo == null) {
      this.adInfo = await getAdRequest();
    }
    if (this.urgencyTypes === [] && this.vehfixleServicesRows === []) {
      this.getAllUrgencies();
      this.getServiceRequests();
    }
  },
});
</script>
