const createCustomerMutation = (formData) => {
  let latInput;
  let longInput;

  if (formData.selectedTrim === '') {
    // TODO: #92 Don't return null as a string, return null as a null
    formData.selectedTrim = 'null';
  }

  if (formData.requestLoc.x != null && formData.requestLoc.y != null) {
    latInput = '"' + formData.requestLoc.x + '"';
    longInput = '"' + formData.requestLoc.y + '"';
  } else {
    latInput = null;
    longInput = null;
  }

  const query = `
  mutation MyMutation {
    onboardCustomer(
      input: {
      memberPassword: "Temp1234", 
      memberEmail: "${formData.email}", 
      obdiiCode: "${formData.OBDIIReaderCode}", 
      radius: ${formData.selectedRadius}, 
      requestDescription: "${formData.additionalComments}", 
      serviceId: ${formData.selectedProblemType}, 
      urgencyId: ${formData.selectedUrgencyType}, 
      vehicleMake: "${formData.selectedMake}", 
      vehicleModel: "${formData.selectedModel}", 
      vehicleTrim: "${formData.selectedTrim}", 
      vehicleYear: "${formData.selectedYear}", 
      vin: "${formData.vinNo}", 
      zipCode: "${formData.zipCode}",
      requestLat: ${latInput},
      requestLong: ${longInput}
    }
    ) {
      result {
        serviceRequestId
        jwtToken
        memberId
        vendorLeads
      }
    }
  }
  
  `;
  return query;
};

export { createCustomerMutation };
