<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <IonBackButton
            mode="ios"
            default-href="/"
          />
        </ion-buttons>
        <ion-title class="ion-text-center">
          CUSTOMER
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ServiceRequestForm
        :key="reRenderKey"
        :render-key="reRenderKey"
      />
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonContent,
  IonBackButton,
} from '@ionic/vue';
import MenuLeftContextAction from '../components/MenuLeftContextAction.vue';
import ServiceRequestForm from '../components/ServiceRequestForm.vue';
import { getIsUserLoggedIn } from '../services/user/getIsUserLoggedIn';

export default {
  name: 'ServiceRequestCreatePage',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    ServiceRequestForm,
    MenuLeftContextAction,
  },
  data() {
    return {
      reRenderKey: 0,
      userLoggedId: getIsUserLoggedIn(),
    };
  },
  async ionViewWillEnter() {
    this.userLoggedId = getIsUserLoggedIn();
    this.reRenderKey += 1;
  },
};
</script>
