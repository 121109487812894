<template>
  <div>
    <div>
      <div>
        <b>VIN:</b>
        {{ vehicle.vehicle_vin != "null" || "Not Available" }}
      </div>
      <div><b>Make:</b> {{ vehicle.vehicle_make }}</div>
      <div><b>Model: </b>{{ vehicle.vehicle_model }}</div>
      <div><b>Trim: </b>{{ vehicle.vehicle_trim || "Not Available" }}</div>
      <div>
        <b>Vehicle Year:</b>
        {{ vehicle.vehicle_year }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ServiceProviderDashboardItem',
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
  },
});
</script>
