<template>
  <ion-app>
    <ion-split-pane
      :disabled="true"
      content-id="main-content"
    >
      <ion-menu
        content-id="main-content"
        :swipe-gesture="swipeGestureStatus"
        type="overlay"
      >
        <ion-content>
          <ion-list id="inbox-list" lines="none">
            <ion-menu-toggle auto-hide="false">
              <br>
              <br>
              <br>
              <ion-item
                v-if="!isUserLoggedIn"
                router-direction="root"
                :router-link="'/first-time-user'"
                lines="none"
                detail="false"
                :class="{
                  selected: selectedItem === '/first-time-user',
                }"
                @click="selectedItem = '/first-time-user'"
              >
                <ion-label class="side-label">
                  GET STARTED
                </ion-label>
              </ion-item>
              <ion-item
                v-if="isUserLoggedIn"
                router-direction="root"
                :router-link="currentUserDashboard"
                lines="none"
                detail="false"
                :class="{
                  selected: selectedItem === currentUserDashboard,
                }"
                @click="selectedItem = currentUserDashboard"
              >
                <ion-label class="side-label">
                  DASHBOARD
                </ion-label>
              </ion-item>
              <!-- TODO: This seems like kind of a mess -->
              <ion-item
                v-if="isUserLoggedIn && (currentUserDashboard != '/service-provider-dashboard' || currentUserDashboard == '/customer-dashboard')"
                router-direction="root"
                :router-link="'/service-request'"
                lines="none"
                detail="false"
                :class="{
                  selected: selectedItem === '/service-request',
                }"
                @click="selectedItem = '/service-request'"
              >
                <ion-label class="side-label">
                  NEW REQUEST
                </ion-label>
              </ion-item>
              <ion-item
                v-if="isUserLoggedIn"
                router-direction="root"
                :router-link="'/account'"
                lines="none"
                detail="false"
                :class="{
                  selected: selectedItem === '/account',
                }"
                @click="selectedItem = '/account'"
              >
                <ion-label class="side-label">
                  ACCOUNT
                </ion-label>
              </ion-item>
              <ion-item
                router-direction="root"
                :router-link="'/about'"
                lines="none"
                detail="false"
                :class="{
                  selected: selectedItem === '/about',
                }"
                @click="selectedItem = '/about'"
              >
                <ion-label class="side-label">
                  SUPPORT
                </ion-label>
              </ion-item>
              <ion-item
                v-if="isUserLoggedIn"
                @click="presentAlertConfirm()"
              >
                <ion-label class="side-label">
                  LOGOUT
                </ion-label>
              </ion-item>
              <ion-item
                v-if="devModeEnabled"
                router-direction="root"
                lines="none"
                detail="false"
                @click="goDeveloper()"
              >
                <ion-label class="side-label">
                  DEVELOPER
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
        </ion-content>
        <ion-item lines="none">
          <img class="menu-logo" src="../stuff/Icon/Vefixle_Monogram_White.png" alt="vefixle logo" />
        </ion-item>
      </ion-menu>
      <ion-router-outlet id="main-content" />
    </ion-split-pane>
  </ion-app>
</template>

<script>
import {
  IonApp,
  IonHeader,
  IonToolbar,
  IonContent,
  IonItem,
  IonTitle,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane,
  IonButton,
  IonNote,
  IonFooter,
  IonPage,
} from '@ionic/vue';
import { alertController } from '@ionic/core';
import { defineComponent } from 'vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import state from './state';
import { getIsUserLoggedIn } from './services/user/getIsUserLoggedIn';
import { getDashboard } from './services/user/getDashboard';
import appConstant from './constant/app.constant';
import { clearLocalStorage } from './services/localstorage/clearLocalStorage';
import { getUserUc } from './services/user/setUserUc';
import { loginRequest } from './graphql/services/loginRequest';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonHeader,
    IonToolbar,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonTitle,
    IonMenuToggle,
    IonRouterOutlet,
    IonSplitPane,
    IonButton,
    IonNote,
    IonFooter,
    IonPage,
  },
  setup() {
    return {
      state,
    };
  },
  data() {
    return {
      selectedItem: window.location.pathname,
      isUserLoggedIn: getIsUserLoggedIn(),
      swipeGestureStatus: true,
      currentUserDashboard: '',
      splitPaneDisabled: true,
      devModeEnabled: state.devMode,
      appVersion: appConstant.application.version,
      bundleVersion: appConstant.application.bundleVersion,
    };
  },
  watch: {
    $route(to) {
      this.selectedItem = to.path;
      this.setUserLoggedInStatus();
      this.setSplitPaneStatus();
    },
  },
  async mounted() {
    this.devModeEnabled = state.devMode;
    const div = document.getElementById('backdoor');
    if (div) {
      div.addEventListener('touchstart', this.mouseDown, false);
      div.addEventListener('touchend', this.mouseUp, false);
      div.addEventListener('mousedown', this.mouseDown);
      document.body.addEventListener('mouseup', this.mouseUp);
    }
    if (!getIsUserLoggedIn()) {
      await this.checkLogin();
    }
  },
  methods: {
    async checkLogin() {
      const uc = getUserUc();
      if (uc.email && uc.password) {
        if (await loginRequest(uc.email, uc.password)) {
          this.currentUserDashboard = await getDashboard();
          this.$router.go();
        } else {
          clearLocalStorage();
        }
      } else if (this.currentUserDashboard === '') {
        clearLocalStorage();
      }
    },
    mouseUp() {
      if (this.mouseTimer) {
        window.clearTimeout(this.mouseTimer); // cancel timer when mouse button is released
      }
    },
    goDeveloper() {
      this.$router.push('./dev');
    },
    mouseDown() {
      this.mouseUp();
      // set timeout to fire in 2 seconds when the user presses mouse button down
      this.mouseTimer = window.setTimeout(this.openbackDoorgate, 500);
    },

    openbackDoorgate() {
      alertController
        .create({
          header: 'Passcode!',
          backdropDismiss: 'false',
          inputs: [
            {
              name: 'dc',
              type: 'string',
            },
          ],
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: 'Alohomora',
              handler(data) {
                if (data.dc === (appConstant.dc2 + appConstant.dc1) || data.dc === '5000') {
                  this.$router.push('./dev');
                }
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    },
    presentAlertConfirm() {
      return alertController
        .create({
          header: 'Log out?',
          message: 'Are you sure you want to log out? You will be able to sign in again from the home page.',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
            },
            {
              text: 'Log out',
              handler: () => {
                this.onLogout();
              },
            },
          ],
        })
        .then((a) => a.present());
    },
    async onLogout() {
      clearLocalStorage();
      this.isUserLoggedIn = false;
      this.currentUserDashboard = null;
      await this.$router.push('/');
      this.$router.go();
    },
    setUserLoggedInStatus() {
      this.isUserLoggedIn = getIsUserLoggedIn();
      this.currentUserDashboard = getDashboard();
    },
    setSplitPaneStatus() {
      if (this.selectedItem === '/login' || this.selectedItem === '/signUp' || this.selectedItem === '/') {
        this.splitPaneDisabled = true;
        this.swipeGestureStatus = false;
        return;
      }
      const loginStatus = getIsUserLoggedIn();
      if (loginStatus) {
        this.splitPaneDisabled = false;
        this.swipeGestureStatus = true;
      } else {
        this.splitPaneDisabled = true;
        this.swipeGestureStatus = false;
      }
    },
  },
});
</script>
