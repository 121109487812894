const createVendorRequestMutation = (formData, vendorServiceCategories) => {
  const query = `
  mutation MyMutation {
    onboardVendor(
      input: {
      vendorPassword: "${formData.password}", 
      vendorEmail: "${formData.email}", 
      vendorName: "${formData.vendorName}", 
      phoneNumber: "${formData.vendorPhone}", 
      vendorRadius: ${formData.selectedRadius}, 
      serviceCategoryIds: [${vendorServiceCategories}] , 
      vendorAddress: "${formData.vendorAddress}",
      vendorCity: "${formData.vendorCity}",
      vendorZip: "${formData.vendorZip}", 
      vendorState: "${formData.vendorState}"
    }
    ){
      jwtToken
      clientMutationId
    }
  }
`;
  return query;
};
export { createVendorRequestMutation };
