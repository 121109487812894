import { getApolloClient } from "../../apollo/client";
import gql from "graphql-tag";
import { updatePasswordMutation } from "../mutation/updatePassword.mutation";

const updatePasswordRequest = async (memberId, password) => {
  try {
    const res = await getApolloClient().mutate({
      mutation: gql`
        ${updatePasswordMutation(memberId, password)}
      `,
    });
  } catch (err) {
    console.log(err);
  }
};
export { updatePasswordRequest };
