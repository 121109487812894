<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <MenuLeftContextAction />
        </ion-buttons>
        <IonTitle>ACCOUNT</IonTitle>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-card>
        <ion-list>
          <ion-item>
            <ion-label>Delete My Account</ion-label>
            <IonButton
              fill="solid"
              color="danger"
              @click="deleteAccountConfirm()"
            >
              DELETE
            </IonButton>
          </ion-item>
        </ion-list>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonTitle,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  IonNote,
  IonCard,
} from '@ionic/vue';

import { alertController } from '@ionic/core';
import MenuLeftContextAction from '../components/MenuLeftContextAction.vue';
import { getUserUc } from '../services/user/setUserUc';
import { clearLocalStorage } from '../services/localstorage/clearLocalStorage';
import { getIsUserLoggedIn } from '../services/user/getIsUserLoggedIn';
import { deleteUserRequest } from '../graphql/services/deleteUserRequest';

export default defineComponent({
  name: 'AccountPage',
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonTitle,
    MenuLeftContextAction,
    IonList,
    IonItem,
    IonLabel,
    IonCard,
    IonNote,
    IonButton,
  },
  data() {
    return {
    };
  },
  async ionViewWillEnter() {
    if (!getIsUserLoggedIn()) {
      await this.$router.push('/');
      window.location.reload();
    }
  },
  methods: {
    deleteAccountConfirm() {
      alertController
        .create({
          header: 'Are you sure?',
          message: 'Please enter your password to permanently delete your account',
          inputs: [
            {
              name: 'deleteCode',
              type: 'password',
            },
          ],
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: 'Delete',
              handler: (data) => {
                const uc = getUserUc();
                if (data.deleteCode === uc.password) {
                  this.deleteAccount();
                } else {
                  this.deleteAccountFailed();
                }
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    },
    deleteAccountFailed() {
      alertController
        .create({
          header: 'Failed',
          message: 'Your password was invalid. Please try again',
          buttons: [
            {
              text: 'Okay',
              role: 'cancel',
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    },
    deleteAccountSuccess() {
      alertController
        .create({
          header: 'Success!',
          message: "We've received your request and will respond ASAP.",
          buttons: [
            {
              text: 'Okay',
              role: 'cancel',
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
      console.log('account deleted');
    },
    async deleteAccount() {
      const uc = getUserUc();
      await deleteUserRequest(uc.email);
      this.deleteAccountSuccess();
      this.onLogout();
    },
    onLogout() {
      clearLocalStorage();
      this.userLoggedId = false;
      this.currentUserDashboard = null;
      this.$router.push('/');
      window.location.reload();
    },
  },
});
</script>
