const getVehicleModelsQuery = (year,make) => {
    const query = `
    query MyQuery {
        getVehicleModels(vehicleMake: "${make}", vehicleYear: "${year}") {
          edges {
            node
          }
        }
      }
      `;
    return query;
  };
  
  export { getVehicleModelsQuery };
  
