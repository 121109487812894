const getAllUrgenciesQuery = () => {
    const query = `
    query getAllUrgencies {
        allUrgencies {
            edges {
              node {
                urgencyName
                urgencyId
              }
            }
          }
    }`;
    return query;
  };
  
  export { getAllUrgenciesQuery };
  



