const getVehicleTrimsQuery = (year, make, model) => {
    const query = `
    query MyQuery {
        getVehicleTrims(
          vehicleYear: "${year}"
          vehicleMake: "${make}"
          vehicleModel: "${model}"
        ) {
          edges {
            node
          }
        }
      }
      `;
    return query;
  };
  
  export { getVehicleTrimsQuery };
  
