import localstorageConstant from "../../constant/localstorage.constant";


const clearLocalStorage = () => {
  let reservedKeys = localstorageConstant.reservedKeys;
  for (let key in localStorage) {
    if(reservedKeys.includes(key) == false){
      localStorage.removeItem(key)
    }
  }
};

export { clearLocalStorage };
