export default {
  permissionAlert: {
    geoLocation: {
      title: 'Allow Vefixle to use your location?',
      message: 'We use your current location to help you find a mechanic.',
    },
    geoLocationError: {
      title: 'Not able to access your location',
      message: 'Please make sure Vefixle has access to your location.',
    },
  },
};
