import gql from 'graphql-tag';
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client/core';

import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { alertController } from '@ionic/core';
import LocalStorageConst from '../constant/localstorage.constant';
import { getKeyValueFromLocalStorage } from '../services/localstorage/getKeyValueFromLocalStorage';
import { setKeyValueInLocalStorage } from '../services/localstorage/setKeyValueInLocalStorage';
import { removeKeyValueFromLocalStorage } from '../services/localstorage/removeKeyValueFromLocalStorage';
import { getUserUc, setUserUc } from '../services/user/setUserUc';
import state from '../state';
import { clearLocalStorage } from '../services/localstorage/clearLocalStorage';
import { getRoleQuery } from '../graphql/query/getRole.query';
import { authenticateMutation } from '../graphql/mutation/authenticate.mutation';

let apolloClient;
const cache = new InMemoryCache();

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getKeyValueFromLocalStorage(LocalStorageConst.authToken);
  // return the headers to the context so httpLink can read them
  const requestHeaders = {};
  if (token) {
    requestHeaders.authorization = `Bearer ${token}`;
  }
  return {
    headers: {
      ...headers,
      ...requestHeaders,
    },
  };
});

const getApolloClient = () => apolloClient;

const getUserRoleRequest = async () => {
  try {
    const res = await getApolloClient().mutate({
      mutation: gql`
        ${getRoleQuery()}
      `,
    });
    return res.data.getRoleJwt;
  } catch (ex) {
    console.log(ex);
    return null;
  }
};

const requestLogin = async (email, password) => {
  try {
    const res = await getApolloClient().mutate({
      mutation: gql`
        ${authenticateMutation(email, password)}
      `,
    });
    if (res.data.authenticate.jwtToken) {
      const userRole = await getUserRoleRequest();
      if (!getUserUc()) {
        setUserUc({
          email,
          password,
          userRole,
        });
        setKeyValueInLocalStorage(LocalStorageConst.authToken, res.data.authenticate.jwtToken);
        setKeyValueInLocalStorage(LocalStorageConst.actualLogin, true);
      }
      return res.data.authenticate;
    }
    return false;
  } catch (ex) {
    console.log(ex);
    return null;
  }
};

const errorLink = onError(({ networkError }) => {
  if (networkError) {
    if (networkError.statusCode === 401 || networkError.statusCode === 403) {
      const uc = getUserUc();
      if (uc.email && uc.password) {
        removeKeyValueFromLocalStorage(LocalStorageConst.authToken);
        requestLogin(uc.email, uc.password).then((data) => {
          if (!data) {
            clearLocalStorage();
            window.location.reload();
          }
        });
      } else {
        clearLocalStorage();
      }
    } else {
      alertController
        .create({
          header: 'Network ERROR',
          message: 'A network error has occured. Please try again later or contact support.',
          buttons: [
            {
              text: 'Retry',
              handler: () => {
                window.location.reload();
              },
            },
          ],
        })
        .then((a) => a.present());
    }
  }
});

const setApolloClient = () => {
  const url = state.apiUrl;
  const httpLink = createHttpLink({
    uri: url,
  });
  apolloClient = new ApolloClient({
    link: errorLink.concat(authLink.concat(httpLink)),
    cache,
  });
};

export { getApolloClient, setApolloClient };
