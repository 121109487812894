import gql from 'graphql-tag';
import { getApolloClient } from '../../apollo/client';
import { deleteUserMutation } from '../mutation/deleteUser.mutation';

const deleteUserRequest = async (email) => {
  try {
    const res = await getApolloClient().mutate({
      mutation: gql`
        ${deleteUserMutation(email)}
      `,
    });
    return res;
  } catch (ex) {
    console.log(ex);
    return ex;
  }
};

export { deleteUserRequest };
