const deleteUserMutation = (email) => {
    const query = 
    `mutation MyMutation {
        deleteAccount(input: {email: "${email}"}) {
            response
        }
    }`;
    return query;
  };
  
  export { deleteUserMutation};
  