<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <!-- <MenuLeftContextAction /> -->
          <ion-back-button default-href="/" mode="ios" />
        </ion-buttons>
        <IonTitle>DEVELOPER OPTIONS</IonTitle>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item>
          <ion-label>Host</ion-label>
          <ion-input
            v-model="serviceHost"
            placeholder="Enter Service Host"
          />
          <ion-button
            fill="solid"
            color="primary"
            @click="setServiceHostPreference()"
          >
            {{ saveButtonLabel }}
          </ion-button>
        </ion-item>
        <ion-item>
          <ion-label>Environment</ion-label>
          <ion-badge slot="end">
            {{ environment }}
          </ion-badge>
        </ion-item>
        <ion-item>
          <ion-label>Enable Developer Mode</ion-label>
          <ion-toggle
            v-model="devModeEnabled"
            @ion-change="setDevModeEnable();"
          />
        </ion-item>
      </ion-list>
    </ion-content>
    <ion-Note class="ion-text-center">
      {{ appVersion }} / {{ bundleVersion }} / {{ devModeEnabled }}
    </ion-Note>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonTitle,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonBadge,
  IonToggle,
  IonBackButton,
  IonNote,
} from '@ionic/vue';
import state from '../state';
import MenuLeftContextAction from '../components/MenuLeftContextAction.vue';
import capacitorService from '../capacitor/capacitor.service';
import appConstant from '../constant/app.constant';
import { setKeyValueInLocalStorage } from '../services/localstorage/setKeyValueInLocalStorage';
import { getKeyValueFromLocalStorage } from '../services/localstorage/getKeyValueFromLocalStorage';
import { removeKeyValueFromLocalStorage } from '../services/localstorage/removeKeyValueFromLocalStorage';

export default defineComponent({
  name: 'DevPage',
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonTitle,
    MenuLeftContextAction,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonBadge,
    IonToggle,
    IonBackButton,
    IonNote,
  },
  data() {
    return {
      serviceHost: state.apiUrl,
      environment: null,
      devModeEnabled: state.devMode,
      bundleVersion: appConstant.application.bundleVersion,
      appVersion: appConstant.application.version,
      saveButtonLabel: getKeyValueFromLocalStorage('apiHost') ? 'Clear' : 'Save',
    };
  },
  mounted() {
    this.devModeEnabled = state.devMode;
    this.serviceHost = state.apiUrl;
    this.environment = this.getEnvString();
  },
  methods: {
    setServiceHostPreference() {
      if (!getKeyValueFromLocalStorage('apiHost')) {
        setKeyValueInLocalStorage('apiHost', this.serviceHost.trim());
        this.saveButtonLabel = 'Clear';
      } else {
        removeKeyValueFromLocalStorage('apiHost');
        this.saveButtonLabel = 'Save';
      }
      window.location.reload();
    },
    setDevModeEnable() {
      if (this.devModeEnabled === true) {
        setKeyValueInLocalStorage('devModeEnabled', 'true');
      } else {
        removeKeyValueFromLocalStorage('devModeEnabled');
      }
      window.location.reload();
    },
    getEnvString() {
      const envString = '';
      if (capacitorService.isCapacitorMobileApp) {
        if (!!window.TFDetect && window.TFDetect.isTFXC) {
          return `${envString}TEST FLIGHT / XC`;
        }
        if (capacitorService.isAndroid) {
          return `${envString}ANDROID`;
        }
        return `${envString}APP STORE`;
      }
      return `${envString}WEBSITE`;
    },
  },
  ionViewWillEnter() {
    // TODO: why is this needed?
    this.environment = this.getEnvString();
  },
});
</script>
